import Swiper from'swiper/bundle';
import 'swiper/css/bundle';

export default class {
    constructor() {
        this.heroSlider();
        this.publishSlider();
        this.voiceSlider();
        this.consultantsSlider();
    }

    heroSlider(){
        const heroSlider = '#js-slider-hero';
        new Swiper (heroSlider,{
            effect:'fade',
            speed:2000,
            easing: "easeOutExpo",
            autoplay: {
                delay: 4000,
                disableOnInteraction: false,
            },
            slidesPerView: 1,
            fadeEffect: {
                crossFade: true
            },
        })
    }

    publishSlider(){
        const publishSlider = '#js-slider-publish';
        new Swiper (publishSlider,{
            effect:'slide',
            speed:1000,
            easing: "easeOutExpo",
            slidesPerView: 1,
            spaceBetween: 10,
            loop: true,
            pagination: {
                el: "#js-slider-publish__pagination",
                clickable: true,
            },
            breakpoints: {
                768: {
                    slidesPerView: 1.5,
                    spaceBetween: 30,
                },
                1140: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                    navigation: {
                        nextEl: "#js-slider-publish__next",
                        prevEl: "#js-slider-publish__prev"
                    },
                }
            }
        })
    }

    voiceSlider(){
        const voiceSlider = '#js-slider-voice';
        const openSlides = document.querySelectorAll(".js-slider-voice__open");
        const swiper = new Swiper (voiceSlider,{
            effect:'slide',
            speed:500,
            easing: "easeOutExpo",
            slidesPerView: 1,
            spaceBetween: 40,
            loop: true,
            autoHeight: true,
            navigation: {
                nextEl: ".js-slider-voice__next",
                prevEl: ".js-slider-voice__prev"
            },
        })
        openSlides.forEach((openSlide) => {
            openSlide.addEventListener("click", () => {
                const modalIndex = openSlide.getAttribute('data-slide-index');
                swiper.slideTo(modalIndex);
            })
        })
    }

    consultantsSlider(){
        const consultantsSlider = '#js-slider-consultants';
        const openSlides = document.querySelectorAll(".js-slider-consultants__open");
        const swiper = new Swiper (consultantsSlider,{
            effect:'slide',
            speed:500,
            easing: "easeOutExpo",
            slidesPerView: 1,
            spaceBetween: 40,
            loop: true,
            autoHeight: true,
            navigation: {
                nextEl: ".js-slider-consultants__next",
                prevEl: ".js-slider-consultants__prev"
            },
        })
        openSlides.forEach((openSlide) => {
            openSlide.addEventListener("click", () => {
                const modalIndex = openSlide.getAttribute('data-slide-index');
                swiper.slideTo(modalIndex);
            })
        })
    }

    // seminarSliderPC(){
    //     this.mainSlider = '#js-slider-seminar-pc';
    //     this.thumbnailsSlider = '#js-slider-seminar-pc__thumbnails';
    //     const main = document.querySelector(this.mainSlider);
    //     const thumbnails = document.querySelector(this.thumbnailsSlider);
    //     this.mainSlides = document.querySelectorAll( this.mainSlider + ' .swiper-slide' );
    //     this.thumbnailsSlides = document.querySelectorAll( this.thumbnailsSlider + ' .swiper-slide' );

    //     if(main){
    //         new Swiper(this.mainSlider, {
    //             effect:'slide',
    //             speed:1000,
    //             easing: "easeOutExpo",
    //             slidesPerView: 1,
    //             spaceBetween: 10,
    //             navigation: {
    //                 nextEl: "#js-slider-seminar-pc__next",
    //                 prevEl: "#js-slider-seminar-pc__prev"
    //             },
    //             slideToClickedSlide: false,
    //         });
    //         // const thumbnailsSlider = new Swiper(this.thumbnailsSlider, {
    //         //     speed:250,
    //         //     slidesPerView: 4,
    //         //     spaceBetween: 10,
    //         //     loopedSlides: this.thumbnailsSlides.length,
    //         //     slideToClickedSlide: true,
    //         //     controller:{
    //         //         control: mainSlider
    //         //     }
    //         // });
    //         // mainSlider.on('slideChangeTransitionEnd', () => {
    //         //     const mainModulo = mainSlider.activeIndex%this.mainSlides.length;
    //         //     const thumbModulo = thumbnailsSlider.activeIndex%this.thumbnailsSlides.length;
    //         //     if( mainModulo != thumbModulo){
    //         //         thumbnailsSlider.slideToLoop(mainModulo);
    //         //     }
    //         // })
    //     }
    // }


    recommendSlider(){
        const recommendSlider = '#js-slider__recommend';
        new Swiper (recommendSlider,{
            effect:'slide',
            speed:1000,
            easing: "easeOutExpo",
            // autoplay: {
            //     delay: 4000
            // },
            slidesPerView: 1.85,
            spaceBetween: 60,
            pagination: {
                el: "#js-slider__recommend--pagination",
                clickable: true,
            },
            navigation: {
                nextEl: "#js-slider__recommend--next",
                prevEl: "#js-slider__recommend--prev"
            },
        })
    }
}