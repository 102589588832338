import {gsap} from "gsap";


export default class {
    constructor() {
        this.accordionClass = ".js-accordion";
        this.accordionTriggerClass = ".js-accordion-trigger";
        this.accordionContentsClass = ".js-accordion-contents";
        this.activeClass = 'is-active';

        this.init();
    }
    init() {
        this.accordions = document.querySelectorAll(this.accordionClass);
        this.triggers = []
        this.contents = []
        if(this.accordions.length > 0) {
            this.accordions.forEach(  (accordion, i) => {
                const triggers = accordion.querySelectorAll(this.accordionTriggerClass);
                const contents = accordion.querySelectorAll(this.accordionContentsClass);

                this.triggers.push(triggers);
                this.contents.push(contents);

                if(triggers.length > 0) {
                    triggers.forEach( (trigger, index) => {

                        this._Events( trigger, i, index. triggers, accordion );
                    })
                }

                if(contents.length > 0) {
                    contents.forEach( (content, index) => {
                        // is-activeをもっていないとき、高さを0に
                        if( !content.classList.contains(this.activeClass)) {
                            content.style.height = 0;
                        }
                    })
                }
            })
        }
    }
    _Events(el, i, index, triggers, accordion) {
        // 要素をクリックした際
        el.addEventListener('click', (e) => {
            e.preventDefault();
            const id = el.getAttribute('aria-controls');
            const content = document.querySelector('#' + id)
            if(content) {
                if( !content.classList.contains( this.activeClass ) ) {
                    this.close(el, content);
                    e.currentTarget.classList.add( this.activeClass );
                    this._animation(content, 'open')
                } else {
                    this.close(el, content);
                }
            }
        });
        // キーボードの操作にて
        // el.addEventListener( 'keydown', (e) => {

        // })
    }
    close( trigger, content ) {
        if(trigger) {
            trigger.classList.remove( this.activeClass );
        }
        if(content) {
            content.classList.remove( this.activeClass );
            this._animation(content, 'close')
        }
    }
    _animation( el, type ) {
        if( type=="open" ) {
            el.classList.add(this.activeClass);
            el.style.height = 'auto';
            let height = el.clientHeight;
            el.setAttribute('data-height', height);
            el.setAttribute('aria-hidden', 'false');
            el.style.height = 0;

            gsap.to(el, {
                height: height,
                duration: 0.4,
                ease: "expo.out"
            })
        } else {
            el.classList.remove( this.activeClass );
            el.setAttribute('aria-hidden', 'true');
            gsap.to(el, {
                height: 0,
                duration: 0.4,
                ease: "expo.out"
            })
        }
    }
}