import Headroom from "headroom.js";

export default class {
    constructor() {
        this.init();
    }
    init() {
        const header = document.getElementById('js-header');
        const nav = document.getElementById('js-nav');
        const hamburger = document.getElementById('js-drawer-hamburger');

        if(header) {
            const headroomHeader = new Headroom(header);
            headroomHeader.init();
        }
        if(nav) {
            const headroomNav = new Headroom(nav);
            headroomNav.init();
        }
        if(hamburger) {
            const headroomHamburger = new Headroom(hamburger);
            headroomHamburger.init();
        }
    }
}