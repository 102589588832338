export default class {
    constructor() {
        this.wrapper = '#js-wrapper';
        this.hero = '#js-hero';
        this.catch = '.js-hero-catch';
        this.activeClass = 'is-active';
        this.init();
        this.heroInit();
    }
    init() {
        const wrapper = document.querySelector(this.wrapper);
        if(wrapper) {
            setTimeout(() =>{
                wrapper.classList.add(this.activeClass);
            }, 400)
        }
    }
    heroInit(){
        const hero = document.querySelector(this.hero);
        if(hero) {
            const catches = document.querySelectorAll(this.catch);
            if(catches.length > 0) {
                catches.forEach((el) => {
                    el.classList.add(this.activeClass);
                })
            }
        }
    }
}