export default class {
    constructor() {
			this.dropdownClass = ".js-dropdown";
			this.dropdownTriggerClass = ".js-dropdown-trigger";
			this.dropdownContentsClass = ".js-dropdown-contents";
			this.activeClass = 'is-active';
			this.init();
    }
    init() {
			this.dropdowns = document.querySelectorAll(this.dropdownClass);
			this.triggers = []
			this.contents = []
			if(this.dropdowns.length > 0){
				this.dropdowns.forEach(  (dropdown, i) => {
					const triggers = dropdown.querySelectorAll(this.dropdownTriggerClass);
					const contents = dropdown.querySelectorAll(this.dropdownContentsClass);

					this.triggers.push(triggers);
					this.contents.push(contents);

					if(triggers.length > 0) {
							triggers.forEach( (trigger, index) => {
								trigger.addEventListener("click", (e) => {
									e.preventDefault();
									const content = document.querySelector("#" + trigger.getAttribute('data-dropdown'));
									const self_open = trigger.classList.contains(this.activeClass)
									if(content){
										trigger.classList.remove(this.activeClass);
										content.classList.remove(this.activeClass);
										if(!self_open){
											trigger.classList.add(this.activeClass);
											content.classList.add(this.activeClass);
										}
									}
								})
							})
					}
			})
			}
    }
}