import ScrollHint from 'scroll-hint';

export default class {
    constructor() {
        this.laptopName = "_pc";
        this.mobileName = '_sp';
        this.replaceWidth = 767;
        this.table = '.js-scrollable';
        this.init();
        this.tableScrollable();
    }
    init() {
        const imgs = 'img[src*="' + this.laptopName + '."], img[src*="' + this.mobileName + '."]';
        const targetImgs = document.querySelectorAll(imgs);

        const backgrounds = '[data-bg*="' + this.laptopName + '."], [data-bg*="' + this.mobileName + '."]';
        const targetBackgrounds = document.querySelectorAll(backgrounds);

        if(targetImgs.length > 0) {
            targetImgs.forEach( (img) => {
                this.imageSwitch(img);
                window.addEventListener('resize', () => {
                    this.imageSwitch(img);
                })
            })
        }

        if(targetBackgrounds.length > 0) {
            targetBackgrounds.forEach( (background) => {
                this.backgroundSwitch(background);
                window.addEventListener('resize', () => {
                    this.backgroundSwitch(background);
                })
            })
        }
    }

    imageSwitch(el) {
        const windowWidth = parseInt(window.innerWidth);
        const imgFile = el.getAttribute('src');
        if( windowWidth >= this.replaceWidth ) {
            const laptopFile = imgFile.replace(this.mobileName, this.laptopName);
            el.setAttribute('src', laptopFile);
        } else {
            const mobileFile = imgFile.replace(this.laptopName, this.mobileName);
            el.setAttribute('src', mobileFile);
        }
    }

    backgroundSwitch(el) {
        const windowWidth = parseInt(window.innerWidth);
        const backgroundFile = el.getAttribute('data-bg');
        if( windowWidth >= this.replaceWidth ) {
            const laptopFile = backgroundFile.replace(this.mobileName, this.laptopName);
            el.setAttribute('data-bg', laptopFile);
            el.style.backgroundImage = "url(" + laptopFile + ")";
        } else {
            const mobileFile = backgroundFile.replace(this.laptopName, this.mobileName);
            el.setAttribute('data-bg', mobileFile);
            el.style.backgroundImage = "url(" + mobileFile + ")";
        }
    }

    tableScrollable() {
        const tables = document.querySelectorAll(this.table);
        if(tables.length > 0) {
            // tables.forEach( (table, i) => {
            //     // const elem = document.querySelector(table);
            //     table.outerHTML = "<div class='-table js-scrollable'>" + table.outerHTML + "</div>";
            // })
            new ScrollHint(this.table, {
                i18n: {
                    scrollable: 'スクロールできます',
                    suggestiveShadow: true
                }
            });
        }
    }
}